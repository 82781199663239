<template>
    <div class="h-100 mt-5">
        <!--begin::Header-->
        <div class="card card-custom border-0 p-5 flex-row justify-content-between align-items-center w-100 mb-5" hidden>
            <h3 class="font-weight-bolder text-dark mb-0">Vos classes</h3>
            <b-form-group :style="mobile ? 'width:100%' : 'width:40%'" class="m-0" label-for="filterInput">
                <b-input-group>
                    <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Rechercher" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">Effacer</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <!--begin:Catalogue-->
        <div v-if="displayCatalogVar === true && !mobile">
            <div class="card card-custom flex-row justify-content-between align-items-center px-5">
                <div>
                    <h4 class="font-weight-bolder text-primary my-5">
                        Catalogue Si2P
                        <span class="mx-2 text-muted"> ( {{ catalogClasses.length }} ) </span>
                    </h4>
                </div>
                <div>
                    <router-link
                        :to="{ name: 'catalogue', query: { activeTable: 'catalogue' } }"
                        replace
                        tag="button"
                        class="btn btn-outline-primary rounded-pill"
                        >Voir tout</router-link
                    >
                </div>
            </div>
            <section>
                <vueper-slides
                    class="no-shadow"
                    :visible-slides="4"
                    slide-multiple
                    :bullets="false"
                    :touchable="false"
                    :breakpoints="{
                        767: { visibleSlides: 1, slideMultiple: 2 },
                        1200: { visibleSlides: 2, slideMultiple: 2 },
                        1400: { visibleSlides: 3, slideMultiple: 2 }
                    }"
                >
                    <vueper-slide
                        v-for="item in catalogClasses"
                        :key="item.id"
                        v-bind:id="'slide-' + item.id"
                        class="custom-slide"
                        :video="youtube_parser(item.teaser)"
                    >
                        <!-- le template étant nommé #content il est reconnu comme étant l'intérieur de la slide par vueperslide -->
                        <!-- passer par un template personnalisé nous permet de customiser l'affichage avec un hover -->
                        <template #content>
                            <div id="customSlideContent" @mouseenter="toggleVideoInit">
                                <div class="top-slide">
                                    <img :src="item.miniature" alt="" />
                                </div>
                                <div class="bottom-slide">
                                    <p>{{ item.title.toUpperCase() }}</p>
                                </div>
                                <div class="footer-slide">
                                    <b-button
                                        v-if="$store.getters.currentUser.role === 'gestionnaire'"
                                        pill
                                        variant="outline-primary"
                                        @click="$bvModal.show('modal-confirmation-' + item.id)"
                                    >
                                        Demande de rappel
                                    </b-button>
                                    <b-modal v-bind:id="'modal-confirmation-' + item.id" title="Demande de rappel">
                                        <p class="my-4">
                                            Après confirmation, une demande de rappel sera envoyée à votre interlocuteur. Ce dernier vous
                                            recontactera sous 48h.
                                        </p>
                                        <template #modal-footer>
                                            <b-button
                                                variant="outline-danger"
                                                pill
                                                class="float-right"
                                                @click="$bvModal.hide('modal-confirmation-' + item.id)"
                                            >
                                                Annuler
                                            </b-button>
                                            <b-button
                                                variant="outline-primary"
                                                pill
                                                class="float-right"
                                                @click="classeRappel(item.id), $bvModal.hide('modal-confirmation-' + item.id)"
                                            >
                                                Confirmer
                                            </b-button>
                                        </template>
                                    </b-modal>
                                </div>
                            </div>
                        </template>
                    </vueper-slide>
                </vueper-slides>
            </section>
        </div>
        <!-- end:Catalogue-->
        <!-- begin: Classes à terminer-->
        <div v-if="classes && filteredClasses('started').length > 0">
            <div class="card card-custom flex-row justify-content-between align-items-center px-5">
                <h4 class="font-weight-bolder text-primary my-5">
                    Classes en cours
                    <span class="mx-2 text-muted"> ( {{ filteredClasses('started').length }} ) </span>
                </h4>
            </div>
            <section class="row d-flex mt-2 mb-0 displayWrap">
                <article
                    v-for="item in filteredClasses('started')"
                    :key="item.id"
                    :class="mobile ? 'col-lg-8' : 'col-md-3'"
                    class="card card-stretch gutter-b justify-content-between mr-6 border-primary py-5 border-0"
                    @mouseenter="hoverId = item.id"
                    @mouseleave="hoverId = null"
                >
                    <div class="d-flex flex-column justify-content-between" style="height: 85%">
                        <div
                            v-if="!item.classeInfo.on"
                            v-b-tooltip.hover.bottom="'Classe temporairement indisponible.  Son contenu est en cours de révision.'"
                            style="
                                position: absolute;
                                z-index: 9999;
                                top: 10%;
                                background-color: #ffffff70;
                                backdrop-filter: blur(9px);
                                left: 0;
                            "
                            class="border-top border-bottom border-danger py-1 m-0 text-danger w-100"
                        >
                            <h4 class="my-5 text-center font-weight-bolder">Indisponible</h4>
                        </div>
                        <span class="classe-img-container d-flex justify-content-center" style="height: 12rem; overflow: hidden">
                            <img class="classe-img" :src="item.classeInfo.miniature" alt="image de la classe" />
                        </span>
                        <div class="card-header border-0 justify-content-center py-0 mt-5">
                            <h3 class="card-title font-weight-bolder text-dark text-center mb-0">
                                {{ item.classeInfo.title }}
                            </h3>
                        </div>
                        <div class="progress-bar-container d-flex justify-content-center w-100 mt-5">
                            <div style="width: 80%">
                                <b-progress :max="100" width="100%" height="2vh">
                                    <span v-if="item.lessons[0].totalItemsEnd === 0">0%</span>
                                    <b-progress-bar :value="progression(item)">
                                        <span v-if="item.lessons[0].totalItemsEnd > 0">{{ progression(item) }}%</span>
                                    </b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                        <div class="classe-icon-container mt-6">
                            <div class="classe-icon" v-if="item.classeInfo.duration">
                                <img src="/media/svg/icons/time-icon.svg" alt="Icon horloge" width="20px" />
                                <p>{{ item.classeInfo.duration }} minutes</p>
                            </div>
                            <div class="classe-icon">
                                <img src="/media/svg/icons/cup-icon.svg" alt="Icon trophée" width="20px" />
                                <p>{{ item.classeInfo.goal }}%</p>
                            </div>
                            <div class="classe-icon">
                                <router-link :to="{ name: 'classe', params: { classeUserId: item.id, classeUser: item } }" replace>
                                    <b-icon icon="play-circle-fill" class="develop-card" style="color: black"></b-icon>
                                </router-link>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-6">
                            <b-icon
                                v-if="isIconHovered"
                                icon="plus-circle"
                                variant="primary"
                                class="develop-card"
                                @click="selectItem(item.id)"
                                @mouseover="iconHover(item.id)"
                                v-bind:class="{ hidden: item.id === activeItem }"
                            >
                            </b-icon>
                            <b-icon
                                v-else
                                :icon="activeIcon === item.id ? 'plus-circle-fill' : 'plus-circle'"
                                variant="primary"
                                class="develop-card"
                                @click="selectItem(item.id)"
                                @mouseleave="iconHover(item.id)"
                                v-bind:class="{ hidden: item.id === activeItem }"
                            >
                            </b-icon>
                            <b-icon
                                v-if="isIconHovered"
                                icon="dash-circle"
                                variant="primary"
                                class="hidden develop-card"
                                @click="selectItem(item.id)"
                                @mouseover="iconHover(item.id)"
                                v-bind:class="{ active: item.id === activeItem }"
                            >
                            </b-icon>
                            <b-icon
                                v-else
                                :icon="activeIcon === item.id ? 'dash-circle-fill' : 'dash-circle'"
                                variant="primary"
                                class="hidden develop-card"
                                @click="selectItem(item.id)"
                                @mouseleave="iconHover(item.id)"
                                v-bind:class="{ active: item.id === activeItem }"
                            >
                            </b-icon>
                        </div>
                        <div
                            class="hidden mt-6 px-10 flex-column justify-content-center"
                            style="height: 40%"
                            v-bind:class="{ active: item.id === activeItem }"
                        >
                            <p class="p-0 mt-1 mb-2">Commencé le : {{ parseDate(item.created_at) }}</p>
                            <p class="p-0 mt-0 mb-2" hidden>Dernière progression le :</p>
                            <nl2br
                                v-if="item.classeInfo.description"
                                tag="p"
                                :text="item.classeInfo.description"
                                class-name="nl2br pl-4 border-secondary border-left"
                            />
                        </div>
                    </div>
                    <div class="hidden card-footer pt-5 pb-1 justify-content-center" v-bind:class="{ active: item.id === activeItem }">
                        <router-link :to="{ name: 'classe', params: { classeUserId: item.id, classeUser: item } }" replace>
                            <b-button pill variant="outline-primary"> Reprendre </b-button>
                        </router-link>
                        <b-button v-if="demo" v-on:click="reset(item.id)" pill variant="outline-danger" class="ml-4">
                            Réinitialiser la classe
                        </b-button>
                    </div>
                </article>
            </section>
        </div>
        <!-- end : Classes à terminer-->
        <!-- begin: Classes disponibles-->
        <div v-if="classes && filteredClasses('notStarted').length > 0">
            <div class="card card-custom flex-row justify-content-between align-items-center px-5">
                <h4 class="font-weight-bolder text-primary my-5">
                    Classes disponibles
                    <span class="mx-2 text-muted"> ( {{ filteredClasses('notStarted').length }} ) </span>
                </h4>
            </div>
            <section class="row d-flex mt-2 mb-0 displayWrap">
                <article
                    v-for="item in filteredClasses('notStarted')"
                    :key="item.id"
                    :class="mobile ? 'col-lg-8' : 'col-md-3'"
                    class="card card-stretch gutter-b justify-content-between mr-6 border-primary py-5 border-0"
                    @mouseenter="hoverId = item.id"
                    @mouseleave="hoverId = null"
                >
                    <div class="d-flex flex-column justify-content-between" style="height: 85%">
                        <div
                            v-if="!item.classeInfo.on"
                            v-b-tooltip.hover.bottom="'Classe temporairement indisponible.  Son contenu est en cours de révision.'"
                            style="
                                position: absolute;
                                z-index: 9999;
                                top: 70px;
                                background-color: #ffffff70;
                                backdrop-filter: blur(9px);
                                left: 0;
                            "
                            class="border-top border-bottom border-danger py-1 m-0 text-danger w-100"
                        >
                            <h4 class="my-5 text-center font-weight-bolder">Indisponible</h4>
                        </div>
                        <span class="classe-img-container d-flex justify-content-center" style="height: 12rem; overflow: hidden">
                            <img class="classe-img" :src="item.classeInfo.miniature" alt="image de la classe" />
                        </span>
                        <div class="card-header border-0 justify-content-center py-0 mt-5">
                            <h3 class="card-title font-weight-bolder text-dark text-center mb-0">
                                {{ item.classeInfo.title }}
                            </h3>
                        </div>
                        <div class="classe-icon-container mt-6">
                            <div class="classe-icon" v-if="item.classeInfo.duration">
                                <img src="/media/svg/icons/time-icon.svg" alt="Icon horloge" width="20px" />
                                <p>{{ item.classeInfo.duration }} minutes</p>
                            </div>
                            <div class="classe-icon" v-if="item.classeInfo.goal">
                                <img src="/media/svg/icons/cup-icon.svg" alt="Icon trophée" width="20px" />
                                <p>{{ item.classeInfo.goal }}%</p>
                            </div>
                            <div class="classe-icon">
                                <router-link :to="{ name: 'classe', params: { classeUserId: item.id, classeUser: item } }" replace>
                                    <b-icon icon="play-circle-fill" class="develop-card" style="color: black"></b-icon>
                                </router-link>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-6">
                            <b-icon
                                v-if="isIconHovered"
                                icon="plus-circle"
                                variant="primary"
                                class="develop-card"
                                @click="selectItem(item.id)"
                                @mouseover="iconHover(item.id)"
                                v-bind:class="{ hidden: item.id === activeItem }"
                            >
                            </b-icon>
                            <b-icon
                                v-else
                                :icon="activeIcon === item.id ? 'plus-circle-fill' : 'plus-circle'"
                                variant="primary"
                                class="develop-card"
                                @click="selectItem(item.id)"
                                @mouseleave="iconHover(item.id)"
                                v-bind:class="{ hidden: item.id === activeItem }"
                            >
                            </b-icon>
                            <b-icon
                                v-if="isIconHovered"
                                icon="dash-circle"
                                variant="primary"
                                class="hidden develop-card"
                                @click="selectItem(item.id)"
                                @mouseover="iconHover(item.id)"
                                v-bind:class="{ active: item.id === activeItem }"
                            >
                            </b-icon>
                            <b-icon
                                v-else
                                :icon="activeIcon === item.id ? 'dash-circle-fill' : 'dash-circle'"
                                variant="primary"
                                class="hidden develop-card"
                                @click="selectItem(item.id)"
                                @mouseleave="iconHover(item.id)"
                                v-bind:class="{ active: item.id === activeItem }"
                            >
                            </b-icon>
                        </div>
                        <div
                            class="hidden mt-6 px-10 flex-column justify-content-center"
                            style="height: 40%"
                            v-bind:class="{ active: item.id === activeItem }"
                        >
                            <nl2br
                                v-if="item.classeInfo.description"
                                tag="p"
                                :text="item.classeInfo.description"
                                class-name="nl2br pl-4 border-secondary border-left"
                            />
                        </div>
                    </div>
                    <div class="hidden card-footer pt-5 pb-1 justify-content-center" v-bind:class="{ active: item.id === activeItem }">
                        <router-link :to="{ name: 'classe', params: { classeUserId: item.id, classeUser: item } }" replace>
                            <b-button pill variant="outline-primary"> Voir la classe </b-button>
                        </router-link>
                    </div>
                </article>
            </section>
        </div>
        <!-- end: Classes disponibles-->

        <!-- begin: Classes terminées-->
        <div v-if="classes && filteredClasses('ended').length > 0">
            <div class="card card-custom flex-row justify-content-between align-items-center px-5">
                <h4 class="font-weight-bolder text-primary my-5">
                    Classes terminées
                    <span class="mx-2 text-muted"> ( {{ classes && filteredClasses('ended').length }} ) </span>
                </h4>
            </div>
            <section v-if="classes" class="row d-flex mt-2 mb-0 displayWrap">
                <article
                    v-for="item in filteredClasses('ended')"
                    :key="item.id"
                    :class="mobile ? 'col-lg-8' : 'col-md-3'"
                    class="card card-stretch gutter-b justify-content-between mr-6 border-primary py-5 border-0"
                    @mouseenter="hoverId = item.id"
                    @mouseleave="hoverId = null"
                >
                    <div class="d-flex flex-column justify-content-between" style="height: 85%">
                        <span class="classe-img-container d-flex justify-content-center" style="height: 12rem; overflow: hidden">
                            <img class="classe-img" :src="item.classeInfo.miniature" alt="image de la classe" />
                        </span>
                        <div class="card-header border-0 justify-content-center py-0 mt-5">
                            <h3 class="card-title font-weight-bolder text-dark text-center mb-0">
                                {{ item.classeInfo.title }}
                            </h3>
                        </div>
                        <div class="classe-icon-container mt-6" v-if="item.state === 3">
                            <div class="classe-icon">
                                <span class="svg-icon svg-icon-warning">
                                    <inline-svg src="/media/svg/icons/Clothes/crown.svg" alt="Icon crown" width="25px" />
                                </span>
                                <p style="color: orange; font-weight: bold">{{ item.score }}%</p>
                            </div>
                            <div class="d-flex align-items-center justify-center" style="cursor: pointer">
                                <inline-svg
                                    :id="'diplomeIcon_' + item.id"
                                    v-b-tooltip.hover
                                    title="Télécharger l'attestation"
                                    src="media\svg\diplome.svg"
                                    alt="Télécharger l'attestation"
                                    width="25px"
                                    @click="downloadDocument()"
                                />
                                <b-spinner
                                    class="d-none"
                                    :id="'spinner_' + item.id"
                                    variant="primary"
                                    label="Chargement..."
                                    style="width: 2rem; height: 2rem"
                                />
                            </div>
                            <div class="d-flex align-items-center justify-center" style="cursor: pointer" v-if="item.classeInfo.summary">
                                <inline-svg
                                    v-b-tooltip.hover
                                    title="Voir la fiche synthèse"
                                    src="media\svg\pdf.svg"
                                    alt="Télécharger l'attestation"
                                    width="25px"
                                    @click="downloadSummary(item.classeInfo.summary)"
                                />
                            </div>
                        </div>
                        <div v-else>
                            <p class="text-center m-0u">Vous n'avez pas terminé la classe dans les temps.</p>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-6">
                            <b-icon
                                v-if="isIconHovered"
                                icon="plus-circle"
                                variant="primary"
                                class="develop-card"
                                @click="selectItem(item.id)"
                                @mouseover="iconHover(item.id)"
                                v-bind:class="{ hidden: item.id === activeItem }"
                            >
                            </b-icon>
                            <b-icon
                                v-else
                                :icon="activeIcon === item.id ? 'plus-circle-fill' : 'plus-circle'"
                                variant="primary"
                                class="develop-card"
                                @click="selectItem(item.id)"
                                @mouseleave="iconHover(item.id)"
                                v-bind:class="{ hidden: item.id === activeItem }"
                            >
                            </b-icon>
                            <b-icon
                                v-if="isIconHovered"
                                icon="dash-circle"
                                variant="primary"
                                class="hidden develop-card"
                                @click="selectItem(item.id)"
                                @mouseover="iconHover(item.id)"
                                v-bind:class="{ active: item.id === activeItem }"
                            >
                            </b-icon>
                            <b-icon
                                v-else
                                :icon="activeIcon === item.id ? 'dash-circle-fill' : 'dash-circle'"
                                variant="primary"
                                class="hidden develop-card"
                                @click="selectItem(item.id)"
                                @mouseleave="iconHover(item.id)"
                                v-bind:class="{ active: item.id === activeItem }"
                            >
                            </b-icon>
                        </div>
                        <div
                            class="hidden mt-6 px-10 flex-column justify-content-center"
                            style="height: 40%"
                            v-bind:class="{ active: item.id === activeItem }"
                        >
                            <nl2br
                                v-if="item.classeInfo.description"
                                tag="p"
                                :text="item.classeInfo.description"
                                class-name="nl2br pl-4 border-secondary border-left"
                            />
                        </div>

                        <div class="hidden card-footer pt-5 pb-1 justify-content-center" v-bind:class="{ active: item.id === activeItem }">
                            <router-link :to="{ name: 'classe', params: { classeUserId: item.id, classeUser: item } }" replace>
                                <b-button pill variant="outline-primary"> Voir la classe </b-button>
                            </router-link>
                            <b-button v-if="demo" v-on:click="reset(item.id)" pill variant="outline-danger" class="ml-4">
                                Réinitialiser la classe
                            </b-button>
                        </div>
                    </div>
                </article>
            </section>
        </div>
        <!-- end: Classes terminées-->
        <!-- begin: null-->
        <div v-if="classes.length === 0" class="card card-custom flex-row justify-content-between align-items-center px-5">
            <h4 class="font-weight-bolder text-primary my-5">Vous n'êtes pas encore inscrit dans une classe.</h4>
        </div>
        <!-- end: null-->
    </div>
</template>

<script>
import { VueperSlide, VueperSlides } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import { GET_CLASSEUSER, LIST_USER_CLASSES } from '@/core/services/store/api/classe_users.service';
import { LIST_CATALOG_CLASSES } from '@/core/services/store/api/classe.service';
import { mapGetters } from 'vuex';
import Nl2br from 'vue-nl2br';
import moment from 'moment';
import ApiService from '@/core/services/api.service';
import getDocument from '@/core/services/certificate.service.js';
import axios from 'axios';

export default {
    name: 'user_classes',
    mounted() {
        this.$store.dispatch(LIST_USER_CLASSES, { user_id: this.$store.getters.currentUser.id });
        this.$store.dispatch(LIST_CATALOG_CLASSES, { user_id: this.$store.getters.currentUser.id });
        this.isDemo();
    },
    components: {
        Nl2br,
        VueperSlides,
        VueperSlide
    },
    computed: {
        ...mapGetters({ classes: 'getUserClasses' }),
        ...mapGetters({ catalogClasses: 'getCatalogClasses' }),
        mobile() {
            return window.screen.availWidth > 992 ? false : true;
        }
    },
    data() {
        return {
            isIconHovered: false,
            activeIcon: null,
            activeItem: null,
            isHidden: true,
            filter: null,
            totalRows: null,
            currentPage: 1,
            hoverId: null,
            allNotStart: false,
            allStart: false,
            demo: false,
            init: false,
            displayCatalogVar: true
        };
    },
    methods: {
        filteredClasses(state) {
            if (this.classes) {
                switch (state) {
                    case 'notStarted':
                        //return classes where state is 1 and if classes.sessionInfo exists, where sessionInfo.end_date is not before today
                        return this.classes.filter(
                            (classe) =>
                                classe.state === 1 && (!classe.sessionInfo || !moment(classe.sessionInfo.end_date).isBefore(moment()))
                        );
                    case 'started':
                        //return classes where state is 2 and if classes.sessionInfo exists, where sessionInfo.end_date is not before today
                        return this.classes.filter(
                            (classe) =>
                                classe.state === 2 && (!classe.sessionInfo || !moment(classe.sessionInfo.end_date).isBefore(moment()))
                        );
                    case 'ended':
                        //return classes where state is 3 and if classes.sessionInfo exists, where sessionInfo.end_date is before today
                        return this.classes.filter(
                            (classe) => classe.state === 3 || (classe.sessionInfo && moment(classe.sessionInfo.end_date).isBefore(moment()))
                        );
                }
            }
        },
        displayCatalog() {
            if (
                this.catalogClasses.length === 0 ||
                this.demo ||
                (this.$store.getters.currentUser.hideCatalog === 1 && this.$store.getters.currentUser.role === 'utilisateur') ||
                this.$store.getters.currentUser.role === 'admin' ||
                this.$store.getters.currentUser.role === 'Commercial'
            ) {
                this.displayCatalogVar = false;
            }
        },
        selectItem(itemdId) {
            if (this.activeItem == null) {
                this.activeItem = itemdId;
            } else if (this.activeItem == itemdId) {
                this.activeItem = null;
            } else {
                this.activeItem = null;
                this.activeItem = itemdId;
            }
        },
        iconHover(itemdId) {
            this.activeIcon = itemdId;
            this.isIconHovered = !this.isIconHovered;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        parseDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        isDemo() {
            if (this.$store.getters.currentUser.demo === true) {
                this.demo = true;
            }
        },
        reset(id) {
            ApiService.patch(process.env.VUE_APP_API_URL + '/academy/classeUsers/reset', id)
                .then(() => {
                    this.$store.dispatch(LIST_USER_CLASSES, { user_id: this.$store.getters.currentUser.id });
                })
                .catch((error) => {
                    this.errorMessage = error.message;
                });
        },
        progression(item) {
            let value;
            if (item.totalLessons > 1) {
                var totalItemsEnd = [];
                var totalItem = [];
                for (let lesson in item.lessons) {
                    totalItemsEnd.push(item.lessons[lesson].totalItemsEnd);
                    totalItem.push(item.lessons[lesson].totalItems);
                }
                const reducer = (accumulator, curr) => accumulator + curr;
                value = Math.round((totalItemsEnd.reduce(reducer) / totalItem.reduce(reducer)) * 100);
            } else {
                value = Math.round((item.lessons[0].totalItemsEnd / item.lessons[0].totalItems) * 100);
            }
            return value;
        },
        async downloadDocument() {
            let id = this.hoverId;
            document.getElementById('diplomeIcon_' + id).classList.add('d-none');
            document.getElementById('spinner_' + id).classList.remove('d-none');
            await this.$store
                .dispatch(GET_CLASSEUSER, { classeUser_id: this.hoverId })
                .then((result) => {
                    if (result && result.data) {
                        getDocument([result.data]).then((data) => {
                            data.download(data.docDefinition.info.title);
                        });
                    }
                })
                .finally(() => {
                    document.getElementById('diplomeIcon_' + id).classList.remove('d-none');
                    document.getElementById('spinner_' + id).classList.add('d-none');
                });
        },
        downloadSummary(url) {
            window.open(url, 'blank');
        },
        classeRappel(itemId) {
            axios.post(process.env.VUE_APP_API_URL + '/academy/classes/rappel/' + itemId);
        },

        // charger la fonction après la boucle
        // sinon la fonction appel des éléments n'existent pas encore
        toggleVideoInit() {
            if (this.init === false) {
                this.customIframes();
                this.toggleVideo();
                this.init = true;
            }
        },

        customIframes() {
            let iframes = document.getElementsByTagName('iframe');
            iframes.forEach(function (el) {
                if (el.hasAttribute('allow') === false) {
                    el.setAttribute('allow', 'fullscreen');
                    let iframeSrc = el.src;
                    el.src = iframeSrc;
                }
            });
        },

        // play/pause video teaser
        toggleVideo() {
            let videoSlide = document.getElementsByClassName('vueperslide--has-video');
            videoSlide.forEach(function (el) {
                el.addEventListener('mouseenter', function () {
                    let id = el.id.slice(6);
                    let iframe = document.getElementById('slide-' + id).children[0];
                    iframe.contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
                });
            });
            videoSlide.forEach(function (el) {
                el.addEventListener('mouseleave', function () {
                    let id = el.id.slice(6);
                    let iframe = document.getElementById('slide-' + id).children[0];
                    iframe.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
                });
            });
        },
        // vérification de l'url du teaser
        youtube_parser(url) {
            if (url) {
                let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                let match = url.match(regExp);
                let id = match && match[7].length == 11 ? match[7] : '';
                if (id != false) {
                    return 'https://www.youtube.com/embed/' + id + '?rel=0&controls=1&fs=1&enablejsapi=1';
                } else {
                    return '';
                }
            }
        }
    },
    watch: {
        catalogClasses() {
            return this.displayCatalog();
        }
    }
};
</script>
<style scoped>
.hidden {
    display: none;
}

.active {
    display: flex;
}

.classe-icon-container {
    display: flex;
    justify-content: space-evenly;
}

.classe-icon {
    display: flex;
    align-items: center;
}

.classe-icon p {
    margin-bottom: 0;
    margin-left: 0.8rem;
}

article {
    margin-bottom: 2vh;
}

article.card {
    box-shadow: 6px 6px 7px 4px rgba(184, 184, 184, 0.68);
    -webkit-box-shadow: 6px 6px 7px 4px rgba(184, 184, 184, 0.68);
    -moz-box-shadow: 6px 6px 7px 4px rgba(184, 184, 184, 0.68);
}

.classe-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.classe-img {
    max-width: 90%;
    max-height: 100%;
    width: auto;
    height: auto;
}

div.card-header {
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progress-bar-container {
    height: 2rem;
}

div.progress span {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scrollPanel::-webkit-scrollbar {
    height: 2vh;
    background-color: #f1f1f1;
    z-index: 9999;
}

.scrollPanel::-webkit-scrollbar-thumb {
    background-color: #085a82;
    border-radius: 1em;
}

.displayScroll {
    height: 60vh;
    overflow-x: auto;
    flex-wrap: nowrap;
}

.displayScroll > article {
    min-width: 25em;
}

.displayWrap {
    padding: 2vh 0;
    height: auto;
    overflow-x: hidden;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start !important;
}

.displayWrap > article {
    margin-bottom: 5vh;
    min-width: 30em;
}

.bi {
    width: 2rem;
    height: 2rem;
}

.develop-card {
    cursor: pointer;
}

.nl2br {
    max-height: 7vh;
}

.customScroll {
    overflow-y: auto;
}

.customScroll::-webkit-scrollbar-track {
    background-color: #f3f6f9;
}

.customScroll::-webkit-scrollbar {
    width: 0.25em;
    background-color: #f3f6f9;
}

.customScroll::-webkit-scrollbar-thumb {
    background-color: #085a82;
}
</style>
<style>
iframe.vueperslide__video {
    display: none;
    height: 75%;
}

div.footer-slide {
    display: none;
}

div#customSlideContent {
    width: 100%;
}

.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--next,
.vueperslides__arrows--outside .vueperslides__arrow--prev {
    right: auto;
    left: 0;
}

.vueperslides--rtl .vueperslides__arrows--outside .vueperslides__arrow--prev,
.vueperslides__arrows--outside .vueperslides__arrow--next {
    left: auto;
    right: 0;
}

.vueperslides__arrows {
    color: currentColor;
}

@media screen and (min-width: 993px) {
    div.vueperslide--has-video.vueperslide--visible:hover > .vueperslide__video {
        display: block;
    }

    div.vueperslide--has-video.vueperslide--visible:hover div.top-slide,
    div.vueperslide--has-video.vueperslide--visible:hover div.bottom-slide {
        display: none;
    }

    div.vueperslide--has-video.vueperslide--visible:hover .footer-slide {
        display: flex;
        justify-content: center;
        margin: 0.5rem 0;
    }

    div.vueperslide--has-video.vueperslide--visible:hover .vueperslide__content {
        justify-content: end;
    }
}

.custom-slide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    height: 230px;
    -webkit-box-shadow: 0px 2px 9px 1px #cbcbcb;
    box-shadow: 0px 2px 9px 1px #cbcbcb;
    transform: scale(0.9);
    transition: all 0.2s ease-in-out;
}

.custom-slide:not(.vueperslide--visible) {
    opacity: 0.4;
}

.custom-slide-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.bottom-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35%;
}

.bottom-slide p {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 17px;
    margin: 0;
    color: #000;
    padding: 0 1rem;
    text-align: center;
}

.top-slide img {
    max-height: 130px;
    max-width: 250px;
}

.vueperslide__content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.vueperslides__parallax-wrapper {
    padding-bottom: 0 !important;
    height: 20rem;
    margin: 2rem 0;
}

.vueperslides__track {
    padding: 0 5rem;
}

.vueperslide--visible:hover {
    transform: scale(1);
}

.vueperslide__content-wrapper:not(.vueperslide__content-wrapper--outside-top):not(.vueperslide__content-wrapper--outside-bottom) {
    justify-content: flex-end;
    text-align: center;
}
</style>
